<template>
  <div class="cornerstone-banner">
    <img
      v-for="item in renderIfReturnArray({ condition: bannerImage })"
      class="cornerstone-banner__image"
      :src="bannerImage"
      loading="lazy"
    />
    <div class="cornerstone-banner__container">
      <h1 class="cornerstone-banner__title" v-html="title" />
      <h2
        class="cornerstone-banner__sub-text"
        v-text="subText"
        v-for="item in renderIfReturnArray({ condition: subText })"
        :key="`subtext_subtitle_${item}`"
      />
      <div class="cornerstone-banner__wrapper">
        <div class="cornerstone-banner__search-tabs">
          <a
            :href="getTabLink(tab)"
            class="cornerstone-banner__search-tab"
            v-for="tab in searchTabs"
            :key="`tab_${tab.link || tab.route_name}`"
            :class="{
              'cornerstone-banner__search-tab--active': tab.name === activeTab
            }"
            v-html="`${setTitleCase({ text: tab.name })} <span>Search</span>`"
          />
        </div>
        <tz-search
          v-for="peopleSearch in renderIfReturnArray({
            condition: !hasAddressSearch
          })"
          :key="peopleSearch"
          :display-search-text="true"
          start-label=""
          :prefill-fields="prefillFields"
          :people-link="peopleUrl"
          :phone-link="phoneUrl"
          :fallback-fields="fallbackFields"
          :people-invalid-regex="getRegex({ regexType: 'peopleInvalidRegex' })"
          :text-search-icon="buttonIcon"
          :text-search-icon-size="searchIconSize"
          :default-phone-label="phonePlaceholder"
          :no-query-string="noQueryString"
          :initial-search-type="initialSearchType"
          :default-state-label="defaultStateOptionLabel"
          @tz-search-override-redirect="handleSearch"
        />
        <div
          class="tz-search"
          v-for="addressSearch in renderIfReturnArray({
            condition: hasAddressSearch
          })"
          :key="addressSearch"
        >
          <div class="tz-search__address">
            <form
              method="POST"
              class="tz-search__form"
              @submit.prevent="searchSubmit"
            >
              <!-- Street Input  -->
              <div class="tz-search__form-group">
                <input
                  type="text"
                  class="tz-search__input-field"
                  name="street"
                  maxlength="255"
                  placeholder="Street"
                  aria-label="Street Name"
                  v-model="addressForm.street"
                  @input="validateStreet"
                  v-bind:class="{
                    'tz-search__input-field--invalid': addressErrors.street,
                    'tz-search__input-field--valid': streetValid
                  }"
                />
              </div>
              <!-- City -->
              <div class="tz-search__form-group">
                <input
                  type="text"
                  class="tz-search__input-field"
                  name="city"
                  maxlength="255"
                  placeholder="City"
                  aria-label="City"
                  v-model="addressForm.city"
                  @input="validateCity"
                  v-bind:class="{
                    'tz-search__input-field--invalid': addressErrors.city,
                    'tz-search__input-field--valid': cityValid
                  }"
                />
              </div>
              <!-- State -->
              <div class="tz-search__form-group">
                <select
                  class="tz-search__input-field tz-search__input-field--select"
                  aria-label="State"
                  name="state"
                  required
                  @change="onStateChange($event)"
                  v-model="addressForm.state"
                >
                  <option
                    v-for="state in statesForDropdown"
                    :value="state.value"
                    :key="state.value"
                    v-text="state.name"
                    :disabled="state.disabled"
                    :selected="state.selected"
                  />
                </select>
              </div>
              <!-- Zip Code Input -->
              <div
                class="tz-search__form-group"
                v-for="zipField in renderIfReturnArray({
                  condition: hasZipField
                })"
                :key="zipField"
              >
                <input
                  type="text"
                  class="tz-search__input-field"
                  name="zip"
                  placeholder="Zip Code"
                  aria-label="Zip Code"
                  maxlength="5"
                  v-model="addressForm.zip"
                  @input="validateZip"
                  v-bind:class="{
                    'tz-search__input-field--invalid': addressErrors.zip,
                    'tz-search__input-field--valid': zipValid
                  }"
                />
              </div>
              <button
                class="tz-search__btn-search"
                :class="'tz-search__btn-search--display-text'"
                aria-label="Search"
              >
                <span class="tz-search__btn-text" aria-hidden="true">
                  Search
                  <img
                    v-for="btnIcon in renderIfReturnArray({
                      condition: buttonIcon
                    })"
                    :key="btnIcon"
                    class="tz-search__btn-text-icon"
                    :src="buttonIcon"
                    alt="Search Icon"
                    :aria-hidden="true"
                    loading="lazy"
                    :height="15"
                    :width="15"
                  />
                </span>
                <span class="tz-search__search-icon" aria-hidden="true" />
              </button>
            </form>
          </div>
        </div>
        <div
          v-for="item in renderIfReturnArray({
            condition: securedText || ratingImage || reviewText
          })"
          :key="`review_${item}`"
          class="cornerstone-banner__reviews"
        >
          <p
            v-for="item in renderIfReturnArray({ condition: securedText })"
            :key="`securedText_${item}`"
            class="cornerstone-banner__reviews-secured"
            v-text="securedText"
          />
          <img
            v-for="item in renderIfReturnArray({ condition: ratingImage })"
            :key="`ratingImage_${item}`"
            class="cornerstone-banner__reviews-image"
            :src="ratingImage"
            alt="5 star rating"
            width="136"
            height="24"
            loading="lazy"
          />
          <tz-markdown
            v-for="item in renderIfReturnArray({ condition: reviewText })"
            :key="`reviewText_${item}`"
            class="cornerstone-banner__reviews-text"
            :content="reviewText"
          />
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
// Components
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import getStatesForDropdown from '@/assets/js/shared/helpers/getStatesForDropdown';
import getGeneralLink from '@/assets/js/shared/helpers/getGeneralLink';

const statesForDropdown = getStatesForDropdown({
  hasTerritories: true,
  useStateAbbrAsValue: true,
  includeAllStatesOption: true,
  allStatesDisplayText: 'State',
  isAllStatesDisabled: true,
  isAllStatesSelected: true
});

export default {
  name: 'wl-cornerstone-banner',
  components: { TzSearch, TzMarkdown },
  data() {
    return {
      setTitleCase,
      getRegex,
      getStatesForDropdown,
      renderIfReturnArray,
      addressErrors: {
        street: false,
        city: false,
        state: false,
        zip: false
      },
      addressForm: {
        city: this.prefillFields.city,
        street: this.prefillFields.street,
        state:
          this.prefillFields.state ??
          this.$store?.getters['location/state'] ??
          '',
        zip: ''
      },
      streetValid: false,
      cityValid: false,
      zipValid: false,
      streetRegex: /^[a-zA-Z0-9\s-]*$/,
      cityRegex: /^[a-zA-Z\s-]*$/,
      zipRegex: /^[0-9]{5}$/,
      statesForDropdown
    };
  },
  computed: {
    street() {
      return this.addressForm.street;
    },
    city() {
      return this.addressForm.city;
    },
    zip() {
      return this.addressForm.zip;
    }
  },
  watch: {
    street(value) {
      const formattedStreetValue = value.replace(/[^a-zA-Z0-9\s-]/g, '');
      this.addressForm.street = formattedStreetValue;

      this.validateStreet();
    },
    city(value) {
      const formattedCityValue = value.replace(/[^a-zA-Z\s-]/g, '');
      this.addressForm.city = formattedCityValue;

      this.validateCity();
    },
    zip(value) {
      const formattedZipValue = value.replace(/[^0-9]/g, '').substr(0, 5);
      this.addressForm.zip = formattedZipValue;
      this.validateZip();
    }
  },
  methods: {
    handleSearch(event) {
      const {
        firstName,
        lastName,
        city,
        state,
        phoneNumber,
        searchType
      } = event;
      const { query } = this.$route;
      let searchLink = '';

      switch (searchType) {
        case 'phone':
          const rawPhoneNumber = phoneNumber.replace(/\D+/g, '');
          const phoneParam = dashedPhoneNumber({ phoneNumber: rawPhoneNumber });
          searchLink = this.$router.resolve({
            name: this.phoneRouteName,
            query: { ...query },
            params: {
              phone: phoneParam,
              phoneNumber: phoneParam
            }
          }).href;
          break;
        case 'people':
          let searchQuery = {
            firstName: setTitleCase({ text: firstName }),
            lastName: setTitleCase({ text: lastName }),
            city: formatCity({ city }),
            state
          };
          // If there are fallback fields, add them to the search query
          if (!this.$route.query.aid) {
            searchQuery = Object.assign(...this.fallbackFields, searchQuery);
          }

          searchLink = this.$router.resolve({
            name: 'flow.searching',
            params: { landingID: this.landingId },
            query: {
              ...query,
              time: Date.now(),
              ...searchQuery
            }
          }).href;
          break;
      }

      window.location = searchLink + (this.hasTrailingSlash ? '/' : '');
    },
    validateStreet() {
      this.streetValid =
        this.streetRegex.test(this.addressForm.street) &&
        this.addressForm.street.trim().length > 0;
    },
    validateCity() {
      this.cityValid =
        this.cityRegex.test(this.addressForm.city) &&
        this.addressForm.city.trim().length > 0;
    },
    validateZip() {
      this.zipValid =
        this.zipRegex.test(this.addressForm.zip) &&
        this.addressForm.zip.trim().length > 0;
    },
    onStateChange(event) {
      this.addressForm.state = event.target.value;
    },
    searchSubmit() {
      // If zip code field is not present, don't need to validate it
      if (!this.hasZipField) {
        delete this.addressErrors.zip;
      }
      // Validate address form
      for (const key in this.addressErrors) {
        if (!this.addressForm[key]) {
          this.addressErrors[key] = true;
        } else {
          this.addressErrors[key] = false;
        }
        if (key === 'zip') {
          this.addressErrors[key] = this.addressForm[key].length < 5;
        }
      }
      const hasErrors = Object.values(this.addressErrors).some(error => error);
      // Prevent form from submitting if there are errors
      if (hasErrors) {
        return;
      }

      this.$emit('wl-cornerstone-banner-address-search', this.addressForm);
    },
    /**
     * Get the link for the tab
     * Fallback to hard-coded link it route name is not found
     * @param {Object} tab { link: String, route_name: String}
     * @returns {String}
     */
    getTabLink(tab) {
      if (tab.link) {
        return tab.link;
      }

      if (tab.route_name && tab.route_name === 'home') {
        return '/';
      }

      return getGeneralLink({
        router: this.$router,
        query: this.$route.query,
        routeType: tab.route_name
      });
    }
  },
  props: {
    // Banner title
    title: {
      type: String,
      required: true
    },
    // Optional text to display below the banner title
    subText: {
      type: String,
      required: false
    },
    // Image that will be used in the banner
    bannerImage: {
      type: String,
      required: false
    },
    // Used for the tabs section above the search form
    searchTabs: {
      type: Array,
      required: true
    },
    // Highlights the active tab
    activeTab: {
      type: String,
      default: 'people'
    },
    // Pre-fills any of the fields for the tz-search component
    prefillFields: {
      type: Object,
      required: true
    },
    // Sets fallback fields for the tz-search component
    fallbackFields: {
      type: Array
    },
    // Sets the button icon used the tz-search component
    buttonIcon: {
      type: String
    },
    // Sets the icon size for the button inside the tz-search component
    searchIconSize: {
      type: Object
    },
    // Copy for the secured content below the search field
    securedText: {
      type: String
    },
    // Copy for the review content below the search field
    reviewText: {
      type: String
    },
    // Image to display for the review section
    ratingImage: {
      type: String
    },
    // Sets the URL location when searching
    peopleUrl: {
      type: String
    },
    // Sets the URL when doing a phone search
    phoneUrl: {
      type: String
    },
    // If true, emit event on submission that handles submission from parent level
    noQueryString: {
      type: Boolean,
      default: false
    },
    // Adds trailing slash at the end of the URL
    hasTrailingSlash: {
      type: Boolean,
      default: false
    },
    defaultStateOptionLabel: {
      type: String,
      default: null
    },
    // Shows the address search form
    hasAddressSearch: {
      type: Boolean,
      default: false
    },
    // Show/hide the zip code field
    hasZipField: {
      type: Boolean,
      default: true
    },
    // Updates the phone input placeholder
    phonePlaceholder: {
      type: String
    },
    // Sets the initial search type. e.g people, phone
    initialSearchType: {
      type: String,
      default: 'people'
    },
    // Landing ID that will be used for the searching page
    landingId: {
      type: String,
      default: ''
    },
    phoneRouteName: {
      type: String,
      default: 'seo.phone'
    }
  }
};
</script>
