/**
 * Returns a general link based on the route type
 * @param {object} opts
 * @param {object} opts.router
 * @param {object} opts.query
 * @param {object} opts.params
 * @param {string} opts.routeType
 * @param {boolean} opts.isPagination
 * @returns {string}
 */
const getGeneralLink = (opts = {}) => {
  if (!opts || !opts.routeType || !opts.router) {
    return '';
  }

  const { href } = opts.router.resolve({
    name: opts.routeType,
    query: opts.query,
    params: opts.params
  });
  const hasTrailingSlash =
    !opts.query || !Object.keys(opts.query).length ? `/` : '';

  // To prevent double trailing slashes
  if (opts.isPagination) {
    return href;
  }

  return href + hasTrailingSlash;
};

export default getGeneralLink;
